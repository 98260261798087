import React, { useEffect, useState } from 'react'
import { Box, Text, IconButton, Icon, Center, Button } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import FormPanel from './index'
import SmarFormPanel from './smart-form-panel'
import { SmartFormContextProvider } from '../../context/smart-form.context'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_FORM_MANAGEMENT } from './../repository/repo.utils'
import { useGetFormRateLimit } from '../../hooks/activity.hooks'

const view = {
  CATEGORY_SELECTOR: 'CATEGORY_SELECTOR',
  ORIGIN_FORM: 'ORIGIN_FORM',
  ERROR_VIEW: 'ERROR_VIEW',
}

const OriginFormPanel = ({
  onClose, ///TODO: On close should ask the user with modal popup with text as your progress will be lost, are you sure?
  from = '',
}) => {
  const [panelView, setpanelView] = useState(view.CATEGORY_SELECTOR)
  const [selectedForm, setSelectedForm] = useState(null)
  const [rateLimit, setRateLimit] = useState({})
  const { mutate } = useGetFormRateLimit()

  useEffect(()=>{
    if(selectedForm&&selectedForm.rate_limit_enabled===1){
      
      mutate(
        {
          activity_type_id:selectedForm.form_workflow_activity_type_id,
        },
        {
          onSuccess:((data)=>{
            
            setRateLimit(data)

          }),
          onError: err => {
            console.log('error occurred', err)
          },
        }
      )
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedForm])

  return (
    <Box
      position="relative"
      h="calc(100vh - 90px)"
      bg="white"
      borderRadius="md"
    >
      {view.ORIGIN_FORM === panelView ? null : (
        <IconButton
          zIndex={1}
          position="absolute"
          top={2}
          right={2}
          bg="white"
          borderRadius="md"
          boxShadow="md"
          aria-label="close"
          size="sm"
          _focus={{
            bg: 'secondary',
          }}
          _hover={{
            bg: 'secondary',
          }}
          onClick={e => {
            sectionDetailedTrack({
              category: GA_CATEGORY_FORM_MANAGEMENT,
              action: 'Origin Form Panel',
              label: 'Close',
            })
            onClose(e)
          }}
          icon={<Icon as={MdClose} w={6} h={6} />}
        />
      )}
      {panelView === view.CATEGORY_SELECTOR ? (
        <FormPanel
          onFormSelect={data => {
            setpanelView(view.ORIGIN_FORM)
            setSelectedForm(data)
          }}
          from={from}
        />
      ) : panelView === view.ERROR_VIEW ? (
        <Box p={4}>
          <Center>
            <Text> Opps something went wrong please try after some time!</Text>
            <Button
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_FORM_MANAGEMENT,
                  action: 'Origin Form Panel',
                  label: 'Error Message',
                })
                setpanelView(view.ORIGIN_FORM)
              }}
            >
              Okay
            </Button>
          </Center>
        </Box>
      ) : (
        <SmartFormContextProvider>
          <SmarFormPanel
            isOrigin={true}
            onClose={d => {
              if (d && d.activity_id) {
                onClose(d)
              } else {
                setpanelView(view.CATEGORY_SELECTOR)
              }
            }}
            formData={{ ...selectedForm, activity_id: 0 }}
            rateLimit={rateLimit}
            
            handleError={() => {
              setpanelView(view.ERROR_VIEW)
            }}
          />
        </SmartFormContextProvider>
      )}
    </Box>
  )
}

export default OriginFormPanel

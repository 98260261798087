/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	HStack,
	Icon,
	IconButton,
	Spacer,
	Text,
	VStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import { BiDownload } from 'react-icons/bi'
import {
	useLoadFormFieldListData,
	useLoadFormAttachments,
	useLoadFieldHistory,
	useLoadWorkFlowList,
} from '../../hooks/form.hooks'
import {
	useSubmitForm,
	useLoadActivityStatusList,
	useLoadActivityTypeList,
	useLoadActivityParticipant,
	useLoadAccessSelectActivity,
	useCheckActivityManager,
} from '../../hooks/activity.hooks'
import { useAuthContext } from '../../context/auth.context'
import { useSharedContext } from '../../context/shared.context'
import { useSmartFormContext } from '../../context/smart-form.context'
import { sharedConst, smartFormConst } from '../../utils/action.constant'
import FormEditView from './edit-view'
import {
	prepareFormSaveParams,
	prepareFinalFieldValueForEdit,
} from '../../utils/form.util'
import { downloadAwsFile } from '../../utils/aws.util'
import {
	toSnakeCase,
	formatDDMMYYYYHHMM,
	formatLocalDDMMYYYYHHMM,
	formatDDMMMYYYY,
	getLocalTimeDate,
} from '../../utils/common.util'
import './style.scss'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../repository/repo.utils'
import { GA_CATEGORY_FORM_MANAGEMENT } from './../repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const FormEditPanel = ({
	isOpen,
	formData,
	activity,
	onClose,
	isOrigin = false,
	refillForm,
	isFormEnable,
}) => {
	const [fieldList, setfieldList] = useState([])
	const [refillVal, setRefillVal] = useState(null)
	const [isSmartForm, setIsSmartForm] = useState(null)
	const [isEditable, setIsEditable] = useState(null)
	const [openModal, setModal] = useState(false)
	const [activityType, setActivityType] = useState(null)
	const [activityStatus, setActivityStatus] = useState(null)
	const [hFieldName, setHFieldName] = useState('')
	const [fieldDesc, setFieldDesc] = useState('')
	const [historyData, setHistoryData] = useState([])
	const [loadSuperAdmins, setloadSuperAdmins] = useState([])
	const [isActivityManager, setIsActivityManager] = useState(false)
	const { mutate, isLoading } = useLoadFormFieldListData()
	const { mutate: mutateSubmitForm } = useSubmitForm()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
	const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
	const { mutate: mutateLoadAttachments } = useLoadFormAttachments()
	const { mutate: mutateLoadFieldHistory } = useLoadFieldHistory()
	const { mutate: mutateLoadParticipants } = useLoadActivityParticipant()
	const { mutate: loadWorkFlowList } = useLoadWorkFlowList()
	const { mutate: loadAccessSelectActivity } = useLoadAccessSelectActivity()
	const { mutate: mutateCheckActivityManager } = useCheckActivityManager()
	const {
		isOpen: isHistoryOpen,
		onOpen: onHistoryOpen,
		onClose: onHistoryClose,
	} = useDisclosure()
	const {
		isOpen: isDescOpen,
		onOpen: onDescOpen,
		onClose: onDescClose,
	} = useDisclosure()
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { fields },
		dispatch,
	} = useSmartFormContext()

	const { dispatch: sharedDispatch } = useSharedContext()

	const toast = useToast()
	const [docsFields, setdocsFields] = useState([])
	const [participantList, setparticipants] = useState([])
	const {
		activity_id,
		activity_type_id,
		parent_status_id,
		activity_status_id,
		activity_type_category_id,
	} = activity

	const loadParticipants = useCallback(params => {
		mutateLoadParticipants(
			{ ...params },
			{
				onSuccess: async data => {
					setparticipants(data ? data.list : [])
				},
			}
		)
	})

	const checkManager = useCallback(params => {
		mutateCheckActivityManager(
			{ ...params },
			{
				onSuccess: async data => {
					if (Array.isArray(data)) {
						const isManager = data.reduce((a, s) => s.is_manager, 0)
						setIsActivityManager(isManager)
					}
				},
			}
		)
	})

	useEffect(() => {
		fetchFieldListData()
		loadParticipants({ activity_id: activity.activity_id })
		checkManager({ activity_id: activity.activity_id })
		loadWorkFlowList(
			{
				statusBasedForms: {
					activity_status_id: parent_status_id || activity_status_id,
					workflow_activity_id: activity.activity_id,
					activity_type_category_id,
					flag: 0,
				},
				submittedForms: {
					flag: 1,
					form_id: 0, // form id should be 0 to load all forms
					activity_id,
					activity_type_id,
					activity_type_category_id,
				},
			},
			{
				onSuccess: async data => {
					let findData = data.find(val => val.form_id === formData.form_id)
					setRefillVal(findData?.form_flag_disable_rollback_refill)
				},
			}
		)
	}, [])

	useEffect(() => {
		if (!!activity) {
			loadAccessSelectActivity(
				{
					activity_type_id: activity?.activity_type_id,
					flag: 0,
				},
				{
					onSuccess: async data => {
						setloadSuperAdmins(data)
					},
					onError: async err => {
						console.log('err', err)
					},
				}
			)
		}
	}, [activity])

	useEffect(() => {
		if (!!fields[formData.form_id]) {
			if (Object.keys(fields[formData.form_id]).length > 0) {
				let attachmentFields = Object.values(fields[formData.form_id]).filter(
					item =>
						!!item.final?.field_data_type_category_id &&
						[11, 12, 13].includes(item.final.field_data_type_category_id) &&
						!!item.field_value
				)
				setdocsFields(attachmentFields)
			}
		}
	}, [fields])

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: formData.organization_id || authData.organization_id,
					account_id: formData.account_id || authData.account_id,
					workforce_id: formData.workforce_id || authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
				is_from_timeline: formData.is_from_timeline,
				submitted_data: formData.submitted_data,
			},
			{
				onSuccess: async data => {
					const isSmart = !!data.map(i => i.next_field_id).filter(Boolean)
						.length
					const isEditable = !!data
						.map(i => i.field_value_edit_enabled)
						.filter(Boolean).length
					setIsSmartForm(!!isSmart)
					setIsEditable(isEditable)
					let finalFields = data
					// let finalFields = []
					// if (!isSmart) {
					// 	finalFields = data
					// 		.map(i => (!!i.field_mandatory_enabled ? i : null))
					// 		.filter(Boolean) //filter out non-mandatory fields in non-smart form
					// }
					if (!!isSmart) {
						finalFields = data.filter(f => !!f.data)
					}
					setfieldList(finalFields)
					let fields = {}
					fields[formData.form_id] = {}
					finalFields.forEach(item => {
						if (!!item.data) {
							fields[formData.form_id][`${item.field_id}`] = item.data
						}
					})
					dispatch({
						type: smartFormConst.SET_FIELD_VALUES,
						formId: formData.form_id,
						fields,
					})
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
		mutateActivityTypes(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityType(data)
				},
			}
		)
		mutateActivityStatus(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityStatus(data)
				},
			}
		)
	}

	const fetchHistory = params => {
		mutateLoadFieldHistory(
			{
				...params,
			},
			{
				onSuccess: async data => {
					setHistoryData(data)
					console.log(data, 'edit view ')
				},
				onError: async err => {
					console.log(err, 'error')
				},
			}
		)
	}

	const onShowHistory = field => {
		let { field_id, form_id } = field
		let form_transaction_id = formData.form_transaction_id
			? formData.form_transaction_id
			: formData.data_form_transaction_id
		setHFieldName(field.field_name)
		onHistoryOpen()
		fetchHistory({
			field_id,
			form_id,
			form_transaction_id,
			activity_id: activity.activity_id,
		})
	}

	const onShowDescription = field => {
		let { field_name, field_description } = field
		setHFieldName(field_name)
		setFieldDesc(field_description)
		onDescOpen()
	}

	const submitForm = (is_resubmit = 0) => {
		const params = {
			fieldList,
			fields,
			formData,
			authData,
			activityStatus,
			activityType,
		}
		mutateSubmitForm(
			{
				...prepareFormSaveParams(params),
				isOrigin,
				is_resubmit: is_resubmit,
				is_status_rollback: 1,
			},
			{
				onSuccess: async data => {
					sharedDispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
					onClose(data)
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
	}

	const downloadZip = () => {
		if (docsFields.length > 0) {
			//download files
			mutateLoadAttachments(
				{
					attachments: docsFields.map(item => item.field_value),
				},
				{
					onSuccess: async res => {
						if (!!res.data.response) {
							downloadAwsFile(res.data.response)
						}
					},
					onError: async err => console.log('Error', err),
				}
			)
		} else {
			toast({
				title: `No attachments available for this Form!`,
				position: `top-right`,
				isClosable: true,
				status: 'info',
			})
		}
	}

	const isClosed = () => {
		if (
			!![155, 156, 163, 168, 171, 178, 181, 182, 186, 187, 80].includes(
				activity.activity_status_type_id
			) //164 ignored
		) {
			return true
		} else {
			return false
		}
	}

	const onDownload = file => {
		if (!!file) downloadAwsFile(file)
	}

	const { operating_asset_id, asset_id } = formData
	const { activity_master_data } = activity
	let form_fill_request
	let delegateParticipant = []
	let formId = !!formData ? formData.form_id : ''
	if (!!activity_master_data) {
		form_fill_request = JSON.parse(activity_master_data).form_fill_request
		if (!!form_fill_request) {
			delegateParticipant = form_fill_request.filter(item => {
				return item.form_id === formId
			})
		}
	}
	let isOwner
	if (!!authData && authData.asset_flag_super_admin) {
		isOwner = !!authData.asset_flag_super_admin
	} else {
		isOwner =
			participantList &&
			participantList.find(participant => {
				return (
					participant.asset_id === authData.asset_id &&
					participant.asset_flag_is_owner
				)
			})
	}
	// const isMyForm = operating_asset_id === authData.operating_asset_id

	// checking on asset_id instead of operating_asset_id
	const isMyForm = asset_id === authData.asset_id

	let isRollBackAccess = delegateParticipant.findIndex(
		user => user.asset_id === authData.asset_id
	)
	const isSuperAdmin = () => {
		const superAdmin = authData.asset_flag_super_admin === 1 ? true : false
		let isSuperAdminCheck
		let isUserHaveAccess
		if (!!loadSuperAdmins.length) {
			isSuperAdminCheck = loadSuperAdmins.find(
				item => item.asset_type_id === authData.asset_type_id
			)
			isUserHaveAccess = loadSuperAdmins.find(
				item => item.asset_id === authData.asset_id
			)
		}
		if (superAdmin || !!isSuperAdminCheck || !!isUserHaveAccess) {
			return true
		} else {
			return false
		}
	}

	const isDisableFieldEdit = () => {
		// Flag Value and Status - Rollback / Refill enablement
		// 0 - Default - Allow
		// 1 - Restrict only Rollback / Refill for all (including super admins)
		// 2 - Restrict only field edit for all (including super admins)
		// 3 - Restrict Rollback / Refill and Edit field for all (including super admins)
		// 4 - Restrict only Rollback / Refill for participants (allow for super admin)
		// 5 - Restrict only field edit for participants (allow for super admin)
		// 6 - Restrict Rollback / Refill and Edit field for participants (allow for super admin)
		let refillFlag = formData.activity_status_disable_rollback_refill
		let adminCheck =
			isRollBackAccess !== -1 || isOwner || isMyForm || isActivityManager
		switch (refillFlag) {
			case 0:
				return !formData.isCompleted && isEditable && !isClosed() && adminCheck
			case 1:
				return !formData.isCompleted && isEditable && !isClosed() && adminCheck
			case 2:
				return false
			case 3:
				return false
			case 4:
				return (
					(!formData.isCompleted && isEditable && !isClosed() && adminCheck) ||
					isSuperAdmin()
				)
			case 5:
				return (
					!formData.isCompleted &&
					isEditable &&
					!isClosed() &&
					adminCheck &&
					isSuperAdmin()
				)
			case 6:
				return (
					!formData.isCompleted &&
					isEditable &&
					!isClosed() &&
					adminCheck &&
					isSuperAdmin()
				)
			default:
				return !formData.isCompleted && isEditable && !isClosed() && adminCheck
		}
	}
	const disable_actions = isDisableFieldEdit()
	// isSmartForm !== null &&
	// (isRollBackAccess !== -1 || isOwner || isMyForm || isSuperAdmin()) &&
	// !formData.isCompleted &&
	// isEditable;

	const isRefillEnabled = val => {
		// Flag Value and Status - Rollback / Refill enablement
		// 0 - Default - Allow
		// 1 - Restrict only Rollback / Refill for all (including super admins)
		// 2 - Restrict only field edit for all (including super admins)
		// 3 - Restrict Rollback / Refill and Edit field for all (including super admins)
		// 4 - Restrict only Rollback / Refill for participants (allow for super admin)
		// 5 - Restrict only field edit for participants (allow for super admin)
		// 6 - Restrict Rollback / Refill and Edit field for participants (allow for super admin)
		let refillFlag = formData.activity_status_disable_rollback_refill
		let adminCheck =
			isRollBackAccess !== -1 || isOwner || isMyForm || isActivityManager

		switch (refillFlag) {
			case 0:
				return adminCheck || isSuperAdmin()
			case 1:
				return false
			case 2:
				return adminCheck || isSuperAdmin()
			case 3:
				return false
			case 4:
				return isSuperAdmin()
			case 5:
				return adminCheck || isSuperAdmin()
			case 6:
				return isSuperAdmin()
			default:
				return true
		}
	}

	return (
		<Drawer
			className={`form-edit-view-drawer-${toSnakeCase(formData.form_name)} ${
				isOpen ? 'expanded' : ''
			}`}
			isOpen={isOpen}
			placement='right'
			onClose={onClose}
			size='lg'
		>
			<DrawerOverlay
				className={`form-edit-drawer-overlay-${toSnakeCase(
					formData.form_name
				)}`}
			/>
			<DrawerContent
				className={`form-edit-drawer-content-${toSnakeCase(
					formData.form_name
				)}`}
			>
				<DrawerCloseButton
					className={`form-edit-drawer-close-button-${toSnakeCase(
						formData.form_name
					)}`}
				/>
				<DrawerHeader
					boxShadow='sm'
					bg='secondary'
					className={`form-edit-drawer-header-${toSnakeCase(
						formData.form_name
					)}`}
				>
					<VStack alignItems='flex-start' flex={1}>
						<Text
							className={`form-edit-drawer-text-${toSnakeCase(
								formData.form_name
							)}`}
							flex={1}
							fontSize='md'
							fontWeight='500'
							textAlign='center'
							textTransform='capitalize'
						>
							{formData.form_name}
						</Text>
						<Divider />
						<HStack justifyContent='flex-end' w='full'>
							<Spacer />
							{isFormEnable && !formData.disableEdit
								? !isSmartForm
									? isRefillEnabled('rollback') && (
											<Button
												className={`form-edit-drawer-rollback-button-${toSnakeCase(
													formData.form_name
												)}`}
												mr={3}
												bg='white'
												_focus={{
													bg: 'secondary',
												}}
												_hover={{
													bg: 'secondary',
												}}
												onClick={() => {
													// sectionDetailedTrack({
													// 	category: GA_CATEGORY_FORM_MANAGEMENT,
													// 	action: 'Form Edit Panel',
													// 	label: 'Rollback',
													// })
													// dataLayerTagManager('button_click', {
													// 	viewName: 'Form Sidebar',
													// 	buttonName: 'Rollback',
													// })
													setModal(true)
												}}
												borderRadius='md'
												boxShadow='md'
												size='sm'
												isDisabled={isClosed()}
											>
												{locale['Rollback']}
											</Button>
									  )
									: isRefillEnabled('refill') && (
											<Button
												className={`form-edit-drawer-refill-button-${toSnakeCase(
													formData.form_name
												)}`}
												mr={3}
												bg='white'
												_focus={{
													bg: 'secondary',
												}}
												_hover={{
													bg: 'secondary',
												}}
												onClick={() => {
													// sectionDetailedTrack({
													// 	category: GA_CATEGORY_FORM_MANAGEMENT,
													// 	action: 'Form Edit Panel',
													// 	label: 'Refill',
													// })
													// dataLayerTagManager('button_click', {
													// 	viewName: 'Form Sidebar',
													// 	buttonName: 'Refill',
													// })
													refillForm(true)
													onClose()
												}}
												borderRadius='md'
												boxShadow='md'
												size='sm'
												isDisabled={isClosed()}
											>
												{locale['Refill']}
											</Button>
									  )
								: null}
							{isFormEnable &&
								!isSmartForm &&
								!formData.disableEdit &&
								disable_actions &&
								isEditable && (
									<Button
										className={`form-edit-drawer-refill-button-${toSnakeCase(
											formData.form_name
										)}`}
										mr={3}
										bg='white'
										_focus={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_FORM_MANAGEMENT,
											// 	action: 'Form Edit Panel',
											// 	label: 'Refill',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Form Sidebar',
											// 	buttonName: 'Bulk Edit',
											// })
											refillForm(true, true)
											onClose()
										}}
										borderRadius='md'
										boxShadow='md'
										size='sm'
										isDisabled={isClosed()}
									>
										{locale['Bulk Edit']}
									</Button>
								)}
							{docsFields.length > 0 ? (
								<IconButton
									className={`form-edit-drawer-download-zip-button-${toSnakeCase(
										formData.form_name
									)}`}
									bg='white'
									borderRadius='md'
									boxShadow='md'
									size='sm'
									aria-label='download'
									cursor='pointer'
									_focus={{
										bg: 'secondary',
									}}
									_hover={{
										bg: 'secondary',
									}}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FORM_MANAGEMENT,
										// 	action: 'Form Edit Panel',
										// 	label: 'Download ZIP',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Form Sidebar',
										// 	buttonName: 'Download ZIP',
										// })
										downloadZip()
									}}
									icon={<Icon as={BiDownload} w={4} h={4} />}
								/>
							) : null}
						</HStack>
					</VStack>
				</DrawerHeader>
				<DrawerBody
					className={`form-edit-drawer-body-${toSnakeCase(formData.form_name)}`}
					height='100%'
				>
					<FormEditView
						isFormEnable={isFormEnable}
						fieldList={fieldList}
						formData={formData}
						activity={activity}
						setFieldList={() => fetchFieldListData()}
						onShowHistory={onShowHistory}
						onShowDescription={onShowDescription}
						isSmartForm={isSmartForm}
						disable_actions={disable_actions}
					/>
				</DrawerBody>
				<DrawerFooter bg='secondary' minH='40px'></DrawerFooter>
			</DrawerContent>
			<Modal
				className={`form-resubmit-modal ${openModal ? 'expanded' : ''}`}
				isOpen={openModal}
				onClose={() => setModal(false)}
			>
				<ModalOverlay className={`form-resubmit-modal-overlay`} />
				<ModalContent className={`form-resubmit-modal-content`}>
					<ModalHeader className={`form-resubmit-modal-header`}>
						<Text
							className={`form-resubmit-text-form-resubmit`}
							fontWeight='400'
						>
							{locale['Form Resubmit']}
						</Text>
					</ModalHeader>
					<ModalCloseButton
						className={`form-resubmit-modal-x-button-${formData.form_name}`}
					/>
					<Divider />
					<ModalBody className={`form-resubmit-modal-body`}>
						<Text
							className={`form-resbmit-text-${toSnakeCase(
								`Are you sure to resubmit form ${formData.form_name} ?`
							)}`}
						>
							{locale['Are you sure to resubmit form']} {formData.form_name} ?
						</Text>
					</ModalBody>
					<ModalFooter className={`form-resubmit-modal-footer`}>
						<Button
							className={`form-resubmit-modal-close-button-${formData.form_name}`}
							colorScheme={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							minW='80px'
							variant='outline'
							mr={3}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Form Edit Panel',
								// 	label: 'Close',
								// })
								setModal(false)
							}}
						>
							{locale['Close']}
						</Button>
						<Button
							className={`form-resubmit-modal-ok-button-${formData.form_name}`}
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							minW='80px'
							variant='solid'
							mr={3}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Form Edit Panel',
								// 	label: 'Submit Form',
								// })
								submitForm(1)
							}}
						>
							{locale['Ok']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isHistoryOpen}
				onClose={onHistoryClose}
			>
				<ModalOverlay />
				<ModalContent
					borderRadius='md'
					bg='white'
					minW='35vw'
					m='auto'
					h='500px'
				>
					<ModalHeader fontSize='lg' fontWeight='400'>
						{hFieldName}
					</ModalHeader>
					<ModalCloseButton zIndex='100' />
					<Divider />
					<ModalBody
						p={0}
						w='100%'
						position='relative'
						className={'field-history-container'}
					>
						<div className='field-history-body'>
							{historyData.length > 0 ? (
								historyData.reverse().map((fieldItem, key) => {
									let updatedFieldValue = ''
									switch (fieldItem.data_type_category_id) {
										case 1:
											if (fieldItem.data_type_id === 4) {
												updatedFieldValue = formatLocalDDMMYYYYHHMM(
													fieldItem.data_entity_datetime_1
												)
											} else {
												updatedFieldValue = formatDDMMMYYYY(
													fieldItem.data_entity_date_1
												)
											}
											break
										case 2:
											if (fieldItem.data_type_id === 6) {
												updatedFieldValue = fieldItem.data_entity_double_1
											} else {
												updatedFieldValue = fieldItem.data_entity_bigint_1
											}
											break
										case 6:
											if (fieldItem.data_type_id === 18) {
												if (fieldItem.data_entity_inline === '{}') {
													updatedFieldValue = fieldItem.data_entity_double_1
												} else {
													updatedFieldValue = fieldItem.data_entity_inline
												}
											} else {
												updatedFieldValue = fieldItem.data_entity_double_1
											}
											break
										case 4:
											if (fieldItem.data_type_id === 59) {
												updatedFieldValue = fieldItem.data_entity_text_1
											} else if (fieldItem.data_type_id === 68) {
												if (fieldItem.data_entity_inline !== '{}') {
													updatedFieldValue =
														!!JSON.parse(fieldItem.data_entity_inline)[0] &&
														!!JSON.parse(fieldItem.data_entity_inline)[0]
															.workflow_activity_title
															? JSON.parse(fieldItem.data_entity_inline)
																	.map(data => data.workflow_activity_title)
																	.join(', ')
															: ''
												}
											} else if (fieldItem.data_type_id === 57) {
												let valSplit = fieldItem.data_entity_text_1.split('|')
												updatedFieldValue = !!valSplit[1] ? valSplit[1] : ''
											} else if (fieldItem.data_type_id === 71) {
												if (fieldItem.data_entity_inline !== '{}') {
													let product_data = JSON.parse(
														fieldItem.data_entity_inline
													)
													updatedFieldValue = (
														<div className='cart-history'>
															<p>
																{locale['Product Category']}:{' '}
																<strong>{product_data.product_tag_name}</strong>
															</p>
															<p>
																{locale['Product Family']}:{' '}
																<strong>
																	{product_data.product_activity_type_name}
																</strong>
															</p>
															<p>
																{locale['Product']}:{' '}
																<strong>
																	{product_data.product_activity_title}
																</strong>
															</p>
															<p>
																{locale['Variant']}:{' '}
																<table border='1' width='100%'>
																	<thead>
																		<tr>
																			<td>{locale['Name']}</td>
																			<td>{locale['Quantity']}</td>
																			<td>{locale['Price']}</td>
																		</tr>
																	</thead>
																	<tbody>
																		{!!product_data.cart_items &&
																			product_data.cart_items.map(
																				(variant, i) => {
																					return (
																						<tr key={i}>
																							<td>
																								{
																									variant.product_variant_activity_title
																								}
																							</td>
																							<td>
																								{
																									variant.product_variant_quantity
																								}
																							</td>
																							<td>
																								{
																									variant.product_variant_unit_price
																								}
																							</td>
																						</tr>
																					)
																				}
																			)}
																	</tbody>
																</table>
															</p>
															<p>
																{locale['Cart Total']}:{' '}
																<strong>{product_data.cart_total_cost}</strong>
															</p>
														</div>
													)
												}
											} else if (fieldItem.data_type_id === 69) {
												if (fieldItem.data_entity_inline !== '{}') {
													let asset = JSON.parse(fieldItem.data_entity_inline)
													updatedFieldValue = !!asset
														? asset
																.map(data => data.operating_asset_first_name)
																.join(',')
														: ''
												}
											} else {
												updatedFieldValue = fieldItem.data_entity_bigint_1
											}
											break
										case 7:
											if (fieldItem.data_type_id === 20) {
												updatedFieldValue = fieldItem.data_entity_text_1
											} else if (fieldItem.data_type_id === 64) {
												let address =
													typeof fieldItem.data_entity_inline === 'string' &&
													fieldItem.data_entity_inline !== '{}'
														? JSON.parse(fieldItem.data_entity_inline)
														: {}
												updatedFieldValue = (
													<div className='cart-history'>
														{!!Object.keys(address).length &&
															Object.keys(address).map((a, i) => {
																return (
																	<p key={i}>
																		{a + `: `}
																		<strong>{address[a]}</strong>
																	</p>
																)
															})}
													</div>
												)
											} else {
												updatedFieldValue = fieldItem.data_entity_text_1
											}
											break
										case 10:
											if (fieldItem.data_type_id === 6) {
												updatedFieldValue = fieldItem.data_entity_double_1
											} else {
												updatedFieldValue = fieldItem.data_entity_text_1
											}
											break
										case 11:
											updatedFieldValue = (
												<a
													style={{
														color: 'blue',
														marginLeft: '10px',
														cursor: 'pointer',
													}}
													onClick={() => {
														// sectionDetailedTrack({
														// 	category: GA_CATEGORY_FORM_MANAGEMENT,
														// 	action: 'Form Edit Panel',
														// 	label: 'Download',
														// })
														onDownload(fieldItem.data_entity_text_1)
													}}
												>
													{fieldItem.data_entity_text_1.split('/').slice(-1)}
												</a>
											)
											break
										case 13:
											if (fieldItem.data_type_id === 65) {
												if (fieldItem.data_entity_inline !== '{}') {
													let contact_data = JSON.parse(
														fieldItem.data_entity_inline
													)
													updatedFieldValue = (
														<div className='cart-history'>
															{!!Object.keys(contact_data).length &&
																Object.keys(contact_data).map((a, i) => {
																	return (
																		<p key={i}>
																			{a + `: `}
																			<strong>
																				{typeof contact_data[a] === 'string'
																					? contact_data[a]
																					: !!Object.keys(contact_data[a])
																							.length &&
																					  Object.keys(contact_data[a]).map(
																							(b, i) => {
																								return (
																									<p
																										key={a + i}
																										style={{
																											marginLeft: '20px',
																										}}
																									>
																										{b + `: `}
																										<strong>
																											{typeof contact_data[a][
																												b
																											] === 'string'
																												? contact_data[a][b]
																												: ''}
																										</strong>
																									</p>
																								)
																							}
																					  )}
																			</strong>
																		</p>
																	)
																})}
														</div>
													)
												}
											} else if (fieldItem.data_type_id === 76) {
												if (
													fieldItem.data_entity_text_1 !== '[]' &&
													fieldItem.data_entity_text_1 !== ''
												) {
													let dropbox = JSON.parse(fieldItem.data_entity_text_1)
													updatedFieldValue = dropbox.map((d, i) => {
														return (
															<div key={i}>
																<a
																	style={{
																		color: 'blue',
																		marginLeft: '10px',
																		cursor: 'pointer',
																	}}
																	onClick={() => {
																		// sectionDetailedTrack({
																		// 	category: GA_CATEGORY_FORM_MANAGEMENT,
																		// 	action: 'Form Edit Panel',
																		// 	label: 'Download',
																		// })
																		onDownload(d.file_url)
																	}}
																>
																	{d.file_name}
																</a>
															</div>
														)
													})
												}
											} else {
												updatedFieldValue = (
													<a
														style={{
															color: 'blue',
															marginLeft: '10px',
															cursor: 'pointer',
														}}
														onClick={() => {
															// sectionDetailedTrack({
															// 	category: GA_CATEGORY_FORM_MANAGEMENT,
															// 	action: 'Form Edit Panel',
															// 	label: 'Download',
															// })
															onDownload(fieldItem.data_entity_text_1)
														}}
													>
														{fieldItem.data_entity_text_1.split('/').slice(-1)}
													</a>
												)
											}
											break
										case 18:
											if (fieldItem.data_type_id === 82) {
												if (fieldItem.data_entity_inline) {
													updatedFieldValue = JSON.parse(
														fieldItem.data_entity_inline
													).total_price
												}
											}
											break
										default:
											updatedFieldValue = fieldItem.data_entity_text_1
											break
									}
									return (
										<div
											className='field-entry-item'
											key={fieldItem.field_id + key}
										>
											{fieldItem.data_type_category_id !== 13 ? (
												<p>
													{locale['Value updated to']}
													<strong> {updatedFieldValue} </strong>
												</p>
											) : (
												<p>
													{locale['Updated a doc to']}
													<div>{updatedFieldValue}</div>
												</p>
											)}

											<small className='text-muted'>
												<i>
													{fieldItem.log_asset_first_name}
													{' (' + fieldItem.asset_first_name + ') '}
													at {getLocalTimeDate(fieldItem.log_datetime)}
												</i>
											</small>
										</div>
									)
								})
							) : (
								<div className='loader'>
									<p>{locale['Loading...']}</p>
								</div>
							)}
						</div>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isDescOpen}
				onClose={onDescClose}
			>
				<ModalOverlay />
				<ModalContent
					borderRadius='md'
					bg='white'
					minW='35vw'
					m='auto'
					h='500px'
				>
					<ModalHeader fontSize='lg' fontWeight='400'>
						{hFieldName}
					</ModalHeader>
					<ModalCloseButton zIndex='100' />
					<Divider />
					<ModalBody
						p={0}
						w='100%'
						position='relative'
						className={'field-history-container'}
					>
						<div className='field-history-body'>
							<label style={{ fontWeight: 'bold', fontSize: '16px' }}>
								{locale['Description']}:
							</label>
							<br />
							{fieldDesc}
						</div>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Drawer>
	)
}

export default FormEditPanel

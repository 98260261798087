import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import React from 'react';

import { FormOnFlyContextProvider } from '../../context/form-onfly-context';
import SmartFormPanel from './smart-form-panel/index';

const FormAddOnFly = ({ formData, setFormOnFly, openDrawer }) => {
  return (
    <Drawer
      className={`form-add-onfly-drawer`}
      isOpen={openDrawer}
      placement="right"
      onClose={() => setFormOnFly(false)}
      size="md"
    >
      <DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
      <DrawerContent className={`form-add-onfly-drawer-content`}>
        <DrawerCloseButton className={`form-add-onfly-drawer-close-button`} />
        <DrawerBody className={`form-add-onfly-drawer-body`}>
          <FormOnFlyContextProvider>
            <SmartFormPanel
              height="100%"
              onClose={() => setFormOnFly(false)}
              isOnFly={openDrawer}
              formData={formData}
              activity={formData}
              handleError={() => {
                console.log('error occurred!');
              }}
              isOrigin={true}
            />
          </FormOnFlyContextProvider>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FormAddOnFly;

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { HStack, Box, IconButton, Avatar, Text, Icon } from '@chakra-ui/react'
import Card from '../../components/card'
import FormCategorySelector from '../../components/form/form-category-selector'
import { useAuthContext } from '../../context/auth.context'
import { useLanguageContext } from '../../context/locale.context'
import { useLoadFormTagMappingList, useLoadFormTagMappingListForDashboard } from '../../hooks/form.hooks'
import { greeting } from '../../utils/common.util'

const FORM_LEVEL = {
  LEVEL_ONE: 1,
  LEVEL_TWO: 2,
  LEVEL_THREE: 3,
}

const FormPanel = ({ onFormSelect, from = '',tag_type_id,setCloseDrawer }) => {
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const [data, setData] = useState({})
  const [tagsList, setTagsList] = useState({})
  const [formList, setFormList] = useState({})
  const [levelFlag, setLevelFlag] = useState(FORM_LEVEL.LEVEL_ONE)
  const [selectedTagType, setSelectedTagType] = useState({
    form_tag_type_id: 0,
  })
  const [selectedTag, setSelectedTag] = useState({ form_tag_id: 0 })
  const { mutate: formTagListMutate, isLoading } = useLoadFormTagMappingList()
  const { mutate: formTagListMutateForDasboard, isLoadingDashboard } = useLoadFormTagMappingListForDashboard()

  useEffect(() => {
    loadFormTagMappingList()
  }, [])

  useEffect(() => {
    loadFormTagMappingList()
  }, [selectedTagType, selectedTag, levelFlag])

  const onSuccess = res => {
    if (levelFlag === FORM_LEVEL.LEVEL_ONE) {
      if(from === 'dashboard'){
        setData({forms:res})
      }else{

        setData(res[0])
      }
    }
    if (levelFlag === FORM_LEVEL.LEVEL_TWO) {
      setTagsList(res[0])
      setFormList({})
    }
    if (levelFlag === FORM_LEVEL.LEVEL_THREE) {
      setFormList(res[0])
    }
  }

  const onSelectTagType = tagType => {
    setSelectedTagType(tagType)
    setSelectedTag(0)
    if(!!tagType?.form_flag_skip_form_tag){
      setLevelFlag(FORM_LEVEL.LEVEL_THREE)
      setTagsList({})
    }else{
      setLevelFlag(FORM_LEVEL.LEVEL_TWO)
    }
  }

  const onSelectTag = tag => {
    setSelectedTag(tag)
    setLevelFlag(FORM_LEVEL.LEVEL_THREE)
  }

  const loadFormTagMappingList = () => {
    if(from === 'dashboard'){
     
      formTagListMutateForDasboard(
        {
          page_start: 0,
          page_limit: 100,
          tag_type_id:tag_type_id,
          tag_id: 0,
        },
        {
          onSuccess,
          onError: err => {
            console.log('error occurred', err)
          },
        }
      )
    }
    else{
      formTagListMutate(
        {
          page_start: 0,
          page_limit: 100,
          tag_type_id:selectedTagType.form_tag_type_id,
          tag_id: selectedTag.form_tag_id || 0,
          flag: from === 'gantt' ? 4 : 3,
          flag_tag_enabled: authData.organization_flag_enable_form_tag,
          level_flag: levelFlag,
        },
        {
          onSuccess,
          onError: err => {
            console.log('error occurred', err)
          },
        }
      )
    }
  }

  return (
    <Card
      bg="white"
      h="calc(100vh - 100px)"
      overflowY="scroll"
      boxShadow="none"
    >
      <HStack my={4}>
        <Avatar name="TONY" size="md" bg={localStorage.getItem('color')} />
        <Box borderRadius="sm" boxShadow="md" p={3} w="full" >
          <Text>
            Hi{' '}
            <Text as="span" fontWeight="800">
              {authData?.operating_asset_first_name || ''}
            </Text>{' '}
            , {locale[greeting()]}
          </Text>
        </Box>
      </HStack>
      <FormCategorySelector
        list={data}
        tagsList={tagsList}
        formList={formList}
        isLoading={from === 'dashboard'? isLoadingDashboard:isLoading}
        onSelect={onSelectTagType}
        onSelectTag={onSelectTag}
        selectedTag={selectedTag}
        selectedTagType={selectedTagType}
        onFormSelect={onFormSelect}
        setCloseDrawer={setCloseDrawer}
        from={from}
      />
    </Card>
  )
}

export default FormPanel
